<template>
   <div class="auth">
    <img class="cover" src="../../assets/img/auth/Cover.svg" alt="">
    <div class="container">
        <Verification />
    </div>
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Reset Page',
components: {
  Verification: defineAsyncComponent( () => import('@/components/auth/Verification_login.vue') ),
},
}
</script>

